<template>
  <div>
    <header class="route-header">
      <div class="route-header--left">
        <img src="@/assets/img/logo.svg" alt="" />
      </div>
      <div class="route-header--right">
        <h1 class="text-3xl">IP Address</h1>
      </div>
    </header>

    <div class="route-header items-center secondary">
      <div class="route-header--box">
        <div class="box">
          <span class="box-header">Country</span>
          <span class="box-label">Kingdom of Bahrain</span>
        </div>
      </div>
      <div class="route-header--box">
        <div class="box">
          <span class="box-header">Total Population</span>
          <span class="box-label">1,472,204</span>
        </div>
      </div>
      <div class="route-header--box">
        <div class="box">
          <span class="box-header">Export Data</span>
          <button class="btn btn-primary btn-outline mr-4 mb-4" :class="year1To4Table ? 'active' : ''" @click="showYear1To4Table">Year 1 to 4</button>
          <button class="btn btn-primary btn-outline btn-small mr-4">
            CSV
          </button>
          <button class="btn btn-primary btn-outline btn-small mr-4">
            PDF
          </button>
          <button class="btn btn-primary btn-outline btn-small">XML</button>
        </div>
      </div>
    </div>
 
    <div class="route-content">

      <br />
      <br />
      <button class="btn btn-primary btn-large">Add new cluster</button>
    </div>
  </div>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/vue/2.5.17/vue.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/axios/0.18.0/axios.min.js"></script>

<script>
export default {
  data() {
    return {
      activeSummary: "annualTotals",
      results: []
    };
  },
  mounted() {
    console.log ("LocationsMain.vue => mounted");
    this.fetchAnnualTotals();
    this.fetchIPAddress();
  },
  computed: {  
  },
  methods: {

    getIPAddress ()
    {
       var ip = require ('ip');

       var x = ip.address();       
       console.log ("ip address: ", x);
    },
    showYear1To4Table() {
      console.log ("button has been pressed.");  

      this.getIPAddress();


      console.log ("Getting data : ");
     this.fetchIPAddress();

    

      fetch ('https://ipinfo.io/json')
         .then (response => response.json()) 
         .then (response => {
          console.log ("info: ", response);})
         .catch ( error => { console.log ("error: ", error); });
         
         
      
      

      //axios.get(url).then(response => {
        //this.results = response.data
      //})

      fetch('https://api.ipify.org?format=json')
        .then(x => x.json())
        .then(({ ip }) => {
        console.log ("ip;", ip);
      });

      
      console.log ("this.results: ", this.results);


      
    },
    fetchAnnualTotals() {
      //this.$store.dispatch("getIPInfo").then((data) => {
        //console.log(data);
      //});
    },
    fetchIPAddress() {
      this.$store.dispatch("getRemoteAddress").then((data) => {
        console.log (data);
    });
   },
  },
};
</script>
