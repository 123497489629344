import axios from "../../plugins/axios";

export default {
  state: {
    namespaced: true,
    administrator: false,
    test: "Surrey",
    specialityName: "",
    locationName: "",
    locationIP: "",
    stageThreeGrandSummary: [],
    dataTypes: [
      { label: "Patient Episodes", string: "patientEpisodes" },
      //{ label: "Space & Property", string: "space" },
      { label: "Finance", string: "finance" },
      { label: "Workforce", string: "workforce" },
    ],
    dataTypes1: [
      { label: "Patient Episodes", string: "patientEpisodes" },
      { label: "Space & Property", string: "space" },
      { label: "Finance", string: "finance" },
      { label: "Workforce", string: "workforce" },
      { label: "Tactical Assumptions", string: "tactical"}
    ],
    dataTypes2: [
      { label: "Diagnostics", string: "diagnostics" },
      { label: "Outpatient Clinics", string: "OPD" },
      { label: "OR", string: "OR" },
      { label: "Emergency Room (ER)", string: "ER" },
      { label: "Bed Flow", string: "bedflow"}
    ],
    specialities: [
      { speciality: "AHPs",                   apiString: "ahpsone-collection" },
      { speciality: "Cardiac Surgery",        apiString: "cardiacsurgeryone-collection"},
      { speciality: "Cardiology",             apiString: "cardiologyone-collection" },
      { speciality: "Dermatology",            apiString: "dermatologyone-collection" },
      { speciality: "Diabetes",               apiString: "diabetesone-collection" },
      { speciality: "Endocrinology",          apiString: "endocrinologyone-collection" },
      { speciality: "ENT",                    apiString: "entone-collection" },
      { speciality: "ER & Acute",             apiString: "eracuteone-collection" },      
      { speciality: "Gastroenterology",       apiString: "gastroenterologyone-collection"},
      { speciality: "General Surgery",        apiString: "generalsurgeryone-collection"},
      { speciality: "Gynaecology",            apiString: "gynaecologyone-collection" },
      { speciality: "Haematology",            apiString: "haematologyone-collection" },
      { speciality: "Home Care",              apiString: "homecareone-collection" },      
      { speciality: "Infectious Diseases",    apiString: "infectiousone-collection"},
      { speciality: "Kidney Services",        apiString: "kidneyservicesone-collection"},
      { speciality: "Labs",                   apiString: "labsone-collection" },
      { speciality: "Long Term Care",         apiString: "longtermone-collection" },
      { speciality: "MaxilloFacial",          apiString: "maxfaxone-collection" },
      { speciality: "Medical Oncology",       apiString: "oncologyone-collection" },
      { speciality: "Mental Health",          apiString: "mentalhealthone-collection" },
      { speciality: "Neurology",              apiString: "neurologyone-collection" },
      { speciality: "Neurosurgery",           apiString: "neurosurgeryone-collection" },
      { speciality: "Obstetrics",             apiString: "obstetricsone-collection" },
      { speciality: "Ophthalmology",          apiString: "opthalmologyone-collection" },
      { speciality: "Orthopaedics & Trauma",  apiString: "orthopaedicsone-collection"},
      { speciality: "Paediatrics",            apiString: "paediatricsone-collection"},
      { speciality: "Plastic Surgery",        apiString: "plasticsurgeryone-collection" },
      { speciality: "Primary Care",           apiString: "primarycareone-collection" },
      { speciality: "Radiology",              apiString: "radiologyone-collection" },
      { speciality: "Respiratory",            apiString: "respiratoryone-collection" },
      { speciality: "Rheumatology",           apiString: "rheumatologyone-collection" },
      { speciality: "Transplant Services",    apiString: "transplantservicesone-collection"},
      { speciality: "Urology",                apiString: "urologyone-collection" },
      { speciality: "Vascular",               apiString: "vascularone-collection" },
    ],
    hospitals: [
      { speciality: "BDF RMS Hospital", string: "RMS" },
      { speciality: "BOC", string : "BOC"},
      { speciality: "KHUH", string: "KHUH" },
      { speciality: "MH", string: "MH"},
      { speciality: "MKCC", string: "MKCC"}//,      
      //{ speciality: "Consolidated Group", string: "Consolidated"},
    ],
    newHospitals: [
      //{ speciality: "BDF RMS Hospital", string: "RMS" },
      { speciality: "BOC", string : "BOC"},
      { speciality: "KHUH", string: "KHUH" },
      { speciality: "MH", string: "MH"},
      { speciality: "MKCC", string: "MKCC"}//,      
      //{ speciality: "Consolidated Group", string: "Consolidated"},
    ],
    stage8Hospitals: [
      { speciality: "RMS", string: "RMS" },
      { speciality: "BOC", string : "BOC"},
      { speciality: "KHUH", string: "KHUH" },
      { speciality: "MH", string: "MH"},
      { speciality: "MKCC", string: "MKCC"}//,      
      //{ speciality: "Consolidated Group", string: "Consolidated"},
    ],
    specialities2: [
      { speciality: "AHPs",                       apiString: "ahpstwo-collection" },
      { speciality: "Cardiac Surgery",            apiString: "cardiacsurgerytwo-collection"},
      { speciality: "Cardiology",                 apiString: "cardiologytwo-collection" },
      { speciality: "Dermatology",                apiString: "dermatologytwo-collection" },
      { speciality: "Diabetes",                   apiString: "diabetestwo-collection" },
      { speciality: "Endocrinology",              apiString: "endocrinologytwo-collection" },
      { speciality: "ENT",                        apiString: "enttwo-collection" },
      { speciality: "ER & Acute",                 apiString: "eracutetwo-collection" },
      { speciality: "Gastroenterology",           apiString: "gastroenterologytwo-collection"},
      { speciality: "General Surgery",            apiString: "generalsurgerytwo-collection"},
      { speciality: "Gynaecology",                apiString: "gynaecologytwo-collection" },
      { speciality: "Haematology",                apiString: "haematologytwo-collection" },
      { speciality: "Home Care",                  apiString: "homecaretwo-collection" },
      { speciality: "Infectious Diseases",        apiString: "infectioustwo-collection"},
      { speciality: "Kidney Services",            apiString: "kidneyservicestwo-collection"},
      { speciality: "Labs",                       apiString: "labstwo-collection" },
      { speciality: "Long Term Care",             apiString: "longtermtwo-collection" },
      { speciality: "MaxilloFacial",              apiString: "maxfaxtwo-collection" },
      { speciality: "Medical Oncology",           apiString: "oncologytwo-collection" },
      { speciality: "Mental Health",              apiString: "mentalhealthtwo-collection" },
      { speciality: "Neurology",                  apiString: "neurologytwo-collection" },
      { speciality: "Neurosurgery",               apiString: "neurosurgerytwo-collection" },
      { speciality: "Obstetrics",                 apiString: "obstetricstwo-collection" },
      { speciality: "Ophthalmology",              apiString: "opthalmologytwo-collection" },
      { speciality: "Orthopaedics & Trauma",      apiString: "orthopaedicstwo-collection" },
      { speciality: "Paediatrics",                apiString: "paediatricstwo-collection" },
      { speciality: "Plastic Surgery",            apiString: "plasticsurgerytwo-collection"},
      { speciality: "Primary Care",               apiString: "primarycaretwo-collection" },
      { speciality: "Radiology",                  apiString: "radiologytwo-collection" },
      { speciality: "Respiratory",                apiString: "respiratorytwo-collection" },
      { speciality: "Rheumatology",               apiString: "rheumatologytwo-collection" },
      { speciality: "Transplant Services",        apiString: "transplantservicestwo-collection"},
      { speciality: "Urology",                    apiString: "urologytwo-collection" },
      { speciality: "Vascular",                   apiString: "vasculartwo-collection" },
    ],
    clinical: [     
      { label: "Primary", string: "primary" },
      { label: "Acute", string: "acute" },
      { label: "Tertiary", string: "tertiary" },
      { label: "Mental Health", string: "mental" },
      { label: "Virtual and Telemedicine", string: "virt" },
      { label: "Long-Term Care", string: "long" },
    ],
    contingencies: [
      { label: "Major Incident Plan", string: "mip"},
      { label: "Disaster Medical Advisory Teams (DMAT) SOPs", string: "DMAT"},
      { label: "Incident Assessment Team SOPs", string: "IAT"},
      { label: "Trigger Point SOPs", string: "TPS"},
      { label: "Pandemic Extreme Scenario SOPs", string: "PES"},
      { label: "Mass Fatality SOPs", string: "MFS"},
      { label: "System Resilience SOPs", string: "SRS"},
      { label: "System Advanced Logistics Planning SOPs", string: "SALP"},

    ],
    countryList: [
      { label: "Australia", string: "australia"},
      { label: "Austria", string: "austria"},
      { label: "Belgium", string: "belgium"},
      { label: "Canada", string: "canada"},
      { label: "Chile", string: "chile"},
      { label: "Colombia", string: "colombia"},
      { label: "Czech Republic", string: "czech-republic"},
      { label: "Denmark", string: "denmark"},
      { label: "Estonia", string: "estonia"},
      { label: "Finland", string: "finland"},
      { label: "France", string: "france"},
      { label: "Germany", string: "germany"},
      { label: "Greece", string: "greece"},
      { label: "Hungary", string: "hungary"},
      { label: "Iceland", string: "iceland"},
      { label: "Ireland", string: "ireland"},
      { label: "Israel", string: "israel"},
      { label: "Italy", string: "italy"},
      { label: "Japan", string: "japan"},
      { label: "Korea", string: "korea"},
      { label: "Latvia", string: "latvia"},
      { label: "Lithuania", string: "lithuania"},
      { label: "Luxembourg", string: "luxembourg"},
      { label: "Mexico", string: "mexico"},
      { label: "Netherlands", string: "netherlands"},
      { label: "New Zealand", string: "new-zealand"},
      { label: "Norway", string: "norway"},
      { label: "Poland", string: "poland"},
      { label: "Portugal", string: "portugal"},
      { label: "Slovak Republic", string: "slovak-republic"},
      { label: "Slovenia", string: "slovenia"},
      { label: "Spain", string: "spain"},
      { label: "Sweden", string: "sweden"},
      { label: "Switzerland", string: "switzerland"},
      { label: "Turkey", string: "turkey"},
      { label: "United Kingdom", string: "united-kingdom"},
      { label: "United States", string: "united-states"},
      ],      
    stage456:
    [ { label: "Stage 4", string: "Stage 4"},
      { label: "Stage 5", string: "Stage 5"},
      { label: "Stage 6", string: "Stage 6"},
    ],
    stage789:
    [ { label: "Stage 7", string: "Stage 7"},
      { label: "Stage 8", string: "Stage 8"},
      { label: "Stage 9", string: "Stage 9"},
    ],
    specialityData: [],
    stageTwo: [],
    stageThree: [],
    stageThreeYearOne: [],
    stageThreeYearTwo: [],
    stageThreeYearThree: [],
    stageThreeYearFour: [],
    stageThreeYearFive: [],
    stageThreeYearSix: [],
    stageFour: [],
    stageFourYearOne: [],
    stageFourYearTwo: [],
    stageFourYearThree: [],
    stageFourYearFour: [],
    stageFourYearFive: [],
    stageFourYearSix: [],
    annualTotalsDataOne: [],
    annualTotalsDataTwo: [],
    annualTotalsData: [],
    stage1Population: [],
    stageFive: [],
    stageFiveInsights: [],
    stageSix: [],
    stageSeven: [],
    stageEight: [],
    stageEightInsights: [],
    stageSevenYearOne: [],
    stageSevenYearTwo: [],
    stageSevenYearThree: [],
    stageSevenYearFour: [],
    stageSevenYearFive: [],
    stageSevenYearSix: [],
    procedurePricing:[],
    packagePricing:[],
    specialTypeData: [],    
    
    stageNine: [],
    stageTen: [],
    stageTenYearOne: [],
    stageTenYearTwo: [],
    stageTenYearThree: [],
    stageTenYearFour: [],
    stageTenYearFive: [],
    stageTenYearSix: [],
    settingsData: [],
    years: [
      //{ year: "2024" },
      { year: "2025" },
      { year: "2026" },
      { year: "2027" },
      { year: "2028" },
      { year: "2029" },
    ],
    yearsStage3: [
      //{ year: "2024" },
      { year: "2025" },
      { year: "2026" },
      { year: "2027" },
      { year: "2028" },
      { year: "2029" },
    ],
    yearsStage41: [
      { year: "2023", api: "year1" },
      { year: "2024", api: "year2" },
      { year: "2025", api: "year3" },
      { year: "2026", api: "year4" },
      { year: "2027", api: "year5" },
    ],
    yearsStage4: [
      { year: "2024", api: "year1" },
      { year: "2025", api: "year2" },
      { year: "2026", api: "year3" },
      { year: "2027", api: "year4" },
      { year: "2028", api: "year5" },
    ],
    yearsStage7: [
      { year: "2025", api: "year1" },
      { year: "2026", api: "year2" },
      { year: "2027", api: "year3" },
      { year: "2028", api: "year4" },
      { year: "2029", api: "year5" },
    ],
    yearsStage10: [
      { year: "2025", api: "year1" },
      { year: "2026", api: "year2" },
      { year: "2027", api: "year3" },
      { year: "2028", api: "year4" },
      { year: "2029", api: "year5" },
    ],
    yearsFinanceProjections: [
      { year: "2023", api: "year1", yr: "1" },
      { year: "2024", api: "year2", yr: "2" },
      { year: "2025", api: "year3", yr: "3" },
      { year: "2026", api: "year4", yr: "4" },
      { year: "2027", api: "year5", yr: "5" },
      { year: "2028", api: "year6", yr: "6" },
      //{ year: "2029", api: "year7", yr: "7" },
    ],
  },
  getters: {
    GET_SPECIALITY_DATA(state) {
      return state.test;
    },
  },
  mutations: {
    CLEAR_SPECIALITY_TYPE_DATA (state)
    {
      //state.stageThreeGrandSummary.length = 0;
      state.specialTypeData.length = 0;
    },
    SAVE_SPECIALITY_NAME(state, specialityName) {
      state.specialityName = specialityName;
    },
    SAVE_SPECIALITY_DATA(state, specialityData) {
      state.specialityData = specialityData;
    },
    SAVE_SPECIALITY_STAGE_TWO(state, specialityData) {
      state.stageTwo = specialityData;
    },
    SAVE_SPECIALITY_STAGE_THREE_DATA (state, specialityData)
    {
      console.log ("SAVE_SPECIALITY_STAGE_THREE_DATA");
      state.stageThreeGrandSummary.push (specialityData);
    },
    SAVE_SPECIALITY_STAGE_THREE(state, specialityData) {
      state.stageThree = specialityData;
    },
    SAVE_SPECIALITY_STAGE_THREE_YEAR_ONE(state, specialityData) {
      state.stageThreeYearOne = specialityData;
    },
    SAVE_SPECIALITY_STAGE_THREE_YEAR_TWO(state, specialityData) {
      state.stageThreeYearTwo = specialityData;
      //state.stageThreeGrandSummary.push(specialityData);
    },
    SAVE_SPECIALITY_STAGE_THREE_YEAR_THREE(state, specialityData) {
      state.stageThreeYearThree = specialityData;
      //state.stageThreeGrandSummary.push(specialityData);
    },
    SAVE_SPECIALITY_STAGE_THREE_YEAR_FOUR(state, specialityData) {
      state.stageThreeYearFour = specialityData;
      //state.stageThreeGrandSummary.push(specialityData);
    },
    SAVE_SPECIALITY_STAGE_THREE_YEAR_FIVE(state, specialityData) {
      state.stageThreeYearFive = specialityData;
      //state.stageThreeGrandSummary.push(specialityData);
    },    
    SAVE_SPECIALITY_STAGE_THREE_YEAR_SIX(state, specialityData) {
      state.stageThreeYearSix = specialityData;
      //state.stageThreeGrandSummary.push(specialityData);
    },
    SAVE_SPECIALITY_STAGE_FOUR(state, specialityData) {
      state.stageFour = specialityData;
    },
    SAVE_SPECIALITY_STAGE_FOUR_YEAR_ONE(state, specialityData) {
      state.stageFourYearOne = specialityData;
    },
    SAVE_SPECIALITY_STAGE_FOUR_YEAR_TWO(state, specialityData) {
      state.stageFourYearTwo = specialityData;
      //state.stageThreeGrandSummary.push(specialityData);
    },
    SAVE_SPECIALITY_STAGE_FOUR_YEAR_THREE(state, specialityData) {
      state.stageFourYearThree = specialityData;
      //state.stageThreeGrandSummary.push(specialityData);
    },
    SAVE_SPECIALITY_STAGE_FOUR_YEAR_FOUR(state, specialityData) {
      state.stageFourYearFour = specialityData;
      //state.stageThreeGrandSummary.push(specialityData);
    },
    SAVE_SPECIALITY_STAGE_FOUR_YEAR_FIVE(state, specialityData) {
      state.stageFourYearFive = specialityData;
      //state.stageThreeGrandSummary.push(specialityData);
    },    
    SAVE_SPECIALITY_STAGE_FOUR_YEAR_SIX(state, specialityData) {
      state.stageFourYearSix = specialityData;
      //state.stageThreeGrandSummary.push(specialityData);
    },
    SAVE_ANNUALTOTALS_DATA_ONE(state, annualTotalsDataOne) {
      state.annualTotalsDataOne = annualTotalsDataOne;
    },
    SAVE_ANNUALTOTALS_DATA_TWO(state, annualTotalsDataTwo) {
      state.annualTotalsDataTwo = annualTotalsDataTwo;
    },
    SAVE_SPECIALITY_STAGE_FIVE(state, specialityData) {
      state.stageFive = specialityData;      
    },
    SAVE_SPECIALITY_STAGE_FIVE_INSIGHTS(state, specialityData) {
      state.stageFiveInsights = specialityData;
    },
    SAVE_SPECIALITY_STAGE_SIX(state, specialityData) {
      state.stageSix = specialityData;
    },
    SAVE_SPECIALITY_STAGE_SEVEN(state, specialityData) {
      state.stageSeven = specialityData;
    },
    SAVE_SPECIALITY_STAGE_SEVEN_YEAR_ONE(state, specialityData) {
      state.stageSevenYearOne = specialityData;
    },
    SAVE_SPECIALITY_STAGE_SEVEN_YEAR_TWO(state, specialityData) {
      state.stageSevenYearTwo = specialityData;
    },
    SAVE_SPECIALITY_STAGE_SEVEN_YEAR_THREE(state, specialityData) {
      state.stageSevenYearThree = specialityData;
    },
    SAVE_SPECIALITY_STAGE_SEVEN_YEAR_FOUR(state, specialityData) {
      state.stageSevenYearFour = specialityData;
    },
    SAVE_SPECIALITY_STAGE_SEVEN_YEAR_FIVE(state, specialityData) {
      state.stageSevenYearFive = specialityData;
    },    
    SAVE_SPECIALITY_STAGE_SEVEN_YEAR_SIX(state, specialityData) {
      state.stageSevenYearSix = specialityData;
    },
    SAVE_SPECIALITY_STAGE_EIGHT(state, specialityData) {
      state.stageEight = specialityData;  
    },
    SAVE_SPECIALITY_STAGE_EIGHT_INSIGHTS(state, specialityData) {
      state.stageEightInsights = specialityData;      
    },
    SAVE_SPECIALITY_STAGE_NINE(state, specialityData) {
      state.stageNine = specialityData;
    },
    SAVE_ADMINISTRATOR_STATE(state, specialityData) {
      state.administrator = specialityData;
    },
    SAVE_LOCATIONS_STATE(state, specialityData) {
      state.locationIP = specialityData;
    },
    
    //---------------------------------------------------------------
    // STAGE TEN
    //
    //---------------------------------------------------------------
    SAVE_SPECIALITY_STAGE_TEN_DATA (state, specialityData)
    {
      console.log ("SAVE_SPECIALITY_STAGE_TEN_DATA");
      state.stageTenGrandSummary.push (specialityData);
    },
    SAVE_SPECIALITY_STAGE_TEN(state, specialityData) {
      state.stageTen = specialityData;
    },
    SAVE_SPECIALITY_STAGE_TEN_YEAR_ONE(state, specialityData) {
      state.stageTenYearOne = specialityData;
    },
    SAVE_SPECIALITY_STAGE_TEN_YEAR_TWO(state, specialityData) {
      state.stageTenYearTwo = specialityData;
    },
    SAVE_SPECIALITY_STAGE_TEN_YEAR_THREE(state, specialityData) {
      state.stageTenYearThree = specialityData;
    },
    SAVE_SPECIALITY_STAGE_TEN_YEAR_FOUR(state, specialityData) {
      state.stageTenYearFour = specialityData;
    },
    SAVE_SPECIALITY_STAGE_TEN_YEAR_FIVE(state, specialityData) {
      state.stageTenYearFive = specialityData;
    },    
    SAVE_SPECIALITY_STAGE_TEN_YEAR_SIX(state, specialityData) {
      state.stageTenYearSix = specialityData;
    },
    SAVE_SPECIALITY_PROCEDURE_PRICING(state, specialityData) {
      state.procedurePricing = specialityData;
    },
    SAVE_SPECIALITY_PACKAGE_PRICING(state, specialityData) {
      state.packagePricing = specialityData;
    },
    SAVE_SPECIALITY_TYPE_DATA(state, specialityData) {
      state.specialTypeData = specialityData;
    },
    SAVE_SETTINGS_DATA (state, data)
    {
      state.settingsData = data;
    },
  },
  actions: {
    GetSpecialTypeData({ commit }, apiPayload) {
      console.log("GetSpecialTypeData: ");
      console.log ("-- apiString   : ", apiPayload.apiString);
      console.log ("-- speciality  : ", apiPayload.speciality);
      console.log ("-- reference   : ", apiPayload.reference);

      return axios
        .get(apiPayload.apiString)
        .then((specialityData) => {
          commit("CLEAR_SPECIALITY_TYPE_DATA");
          commit("SAVE_SPECIALITY_TYPE_DATA", specialityData);
          console.log("GetData", specialityData);
          return specialityData;
        })
        .catch((res) => {
          //console.log ("catch data");
          //console.log(res.response.data)
          throw res.response.data;
        });
    },
    setAdministratorState ({ commit}, value)
    {
        commit("SAVE_ADMINISTRATOR_STATE", value);
    },
    getBDFProcedurePricing({ commit }) {
      console.log ("axios: getBDFProcedurePricing");

      axios
        .get("new_bdf_procedure_pricing")
        .then((thisdata) => {
          commit("SAVE_SPECIALITY_PROCEDURE_PRICING", thisdata);

          console.log ("axios: getBDFProcedurePricing => done");
  
          return thisdata;
        })
        .catch((res) => {
          throw res.response.data;
        });       
    },
    getBDFPackagePricing({ commit }) {
      console.log ("axios: getBDFPackagePricing");

      axios
        .get("new_bdf_package_pricing")
        .then((thisdata) => {
          commit("SAVE_SPECIALITY_PACKAGE_PRICING", thisdata);

          console.log ("axios: getBDFPackagePricing => done");
  
          return thisdata;
        })
        .catch((res) => {
          throw res.response.data;
        });       
    },
    attemptFetchSpecialityData({ commit }, speciality) 
    {
      console.log ("attemptFetchSpecialityData: ", speciality.apiString);

      return axios
      .get(speciality.apiString)
      .then(specialityData => {
          commit('SAVE_SPECIALITY_NAME', speciality.speciality)
          commit('SAVE_SPECIALITY_DATA', specialityData)        
          return specialityData
      })
      .catch(res => {
          //console.log ("catch data");
          //console.log(res.response.data)
          throw res.response.data
      })
    },
    GetSpecialityDataByYear({ commit }, apiPayload) {
      var apiString = "";
      var newSpeciality = "";

      //if 

      console.log("GetSpecialityDataByYear ---------");
      console.log("--Hospital     : ", apiPayload.hospital);
      console.log("--Speciality   : ", apiPayload.speciality);
      console.log("..Stage        : ", apiPayload.stage);
      console.log("--Year         : ", apiPayload.year);
      console.log("---------------------------------");

      console.log("apiPay speciality", apiPayload.speciality.toLowerCase());

      newSpeciality = apiPayload.speciality.toLowerCase();

      if (apiPayload.speciality.toLowerCase() == "diabetes") {
        newSpeciality = "diabetesmetbariatric";
      }

      if (apiPayload.speciality.toLowerCase() == "cardiac surgery") {
        newSpeciality = "cardiac_surgery";
      }

      if (apiPayload.speciality.toLowerCase() == "er & acute") {
        newSpeciality = "er_and_acute";
      }

      if (apiPayload.speciality.toLowerCase() == "general surgery") {
        newSpeciality = "general_surgery";
      }

      if (apiPayload.speciality.toLowerCase() == "home care") {
        newSpeciality = "home_care";
      }

      if (apiPayload.speciality.toLowerCase() == "infectious diseases") {
        newSpeciality = "infectious_diseases";
      }

      if (apiPayload.speciality.toLowerCase() == "kidney services") {
        newSpeciality = "kidney_services";
      }

      if (apiPayload.speciality.toLowerCase() == "long term care") {
        newSpeciality = "long_term_care";
      }

      // Mental Health

      if (apiPayload.speciality.toLowerCase() == "mental health") {
        newSpeciality = "mental_health";
      }

      if (apiPayload.speciality.toLowerCase() == "medical oncology") {
        newSpeciality = "oncology";
      }

      // Orthopaedics & Trauma

      if (apiPayload.speciality.toLowerCase() == "orthopaedics & trauma") {
        newSpeciality = "orthopaedics";
      }

      if (apiPayload.speciality.toLowerCase() == "primary care") {
        newSpeciality = "primary_care";
      }

      if (apiPayload.speciality.toLowerCase() == "plastic surgery") {
        newSpeciality = "plastic_surgery";
      }

      if (apiPayload.speciality.toLowerCase() == "transplant services") {
        newSpeciality = "transplant_services";
      }

//      console.log("new speciality = ", newSpeciality);

      apiString = newSpeciality; 
      
      apiString =
        apiString + "_new_bdf_";
        
      if  (apiPayload.hospital.length > 0)
      { 
        apiString = apiString + apiPayload.hospital.toLowerCase() + "_stage_" + apiPayload.stage + "_" + apiPayload.year;
      }
      else
      {
        apiString = apiString + "stage_" + apiPayload.stage + "_" + apiPayload.year;
      }

    
      //apiString = apiString.toLowerCase();

      //console.log("apiString = ", apiString);

      //console.log(
        //"GetSpecialityDataByYear",
        //apiPayload.year,
        //apiPayload.speciality
      //);

      return axios
        .get(apiString)
        .then((specialityData) => {
          commit("SAVE_SPECIALITY_DATA", specialityData);
          //console.log("specialityData",specialityData);
          return specialityData;
        })
        .catch((res) => {
          //console.log ("catch data");
          //console.log(res.response.data)
          throw res.response.data;
        });
    },
    GetSpecialityDataByYearNew({ commit }, apiPayload) {
      var apiString = "";
      var newSpeciality = "";

      //if 

      console.log("GetSpecialityDataByYearNew -------");
      console.log("--Hospital     : ", apiPayload.hospital);
      console.log("--Speciality   : ", apiPayload.speciality);
      console.log("..Stage        : ", apiPayload.stage);
      console.log("--Year         : ", apiPayload.year);
      console.log("--YearApi      : ", apiPayload.yearApi)
      console.log("---------------------------------");

      console.log("apiPay speciality", apiPayload.speciality.toLowerCase());

      newSpeciality = apiPayload.speciality.toLowerCase();

      if (apiPayload.speciality.toLowerCase() == "diabetes") {
        newSpeciality = "diabetesmetbariatric";
      }

      if (apiPayload.speciality.toLowerCase() == "cardiac surgery") {
        newSpeciality = "cardiac_surgery";
      }

      if (apiPayload.speciality.toLowerCase() == "er & acute") {
        newSpeciality = "er_and_acute";
      }

      if (apiPayload.speciality.toLowerCase() == "general surgery") {
        newSpeciality = "general_surgery";
      }

      if (apiPayload.speciality.toLowerCase() == "home care") {
        newSpeciality = "home_care";
      }

      if (apiPayload.speciality.toLowerCase() == "infectious diseases") {
        newSpeciality = "infectious_diseases";
      }

      if (apiPayload.speciality.toLowerCase() == "kidney services") {
        newSpeciality = "kidney_services";
      }

      if (apiPayload.speciality.toLowerCase() == "long term care") {
        newSpeciality = "long_term_care";
      }

      // Mental Health

      if (apiPayload.speciality.toLowerCase() == "mental health") {
        newSpeciality = "mental_health";
      }

      if (apiPayload.speciality.toLowerCase() == "medical oncology") {
        newSpeciality = "oncology";
      }

      // Orthopaedics & Trauma

      if (apiPayload.speciality.toLowerCase() == "orthopaedics & trauma") {
        newSpeciality = "orthopaedics";
      }

      if (apiPayload.speciality.toLowerCase() == "primary care") {
        newSpeciality = "primary_care";
      }

      if (apiPayload.speciality.toLowerCase() == "plastic surgery") {
        newSpeciality = "plastic_surgery";
      }

      if (apiPayload.speciality.toLowerCase() == "transplant services") {
        newSpeciality = "transplant_services";
      }

//      console.log("new speciality = ", newSpeciality);

      apiString = newSpeciality; 
      
      apiString =
        apiString + "_new_bdf_";
        
      if  (apiPayload.hospital.length > 0)
      { 
        apiString = apiString + apiPayload.hospital.toLowerCase() + "_stage_" + apiPayload.stage + "_" + apiPayload.yearApi;
      }
      else
      {
        apiString = apiString + "stage_" + apiPayload.stage + "_" + apiPayload.yearApi;
      }

      //console.log ("api_call = ", apiString);
    
      //apiString = apiString.toLowerCase();

      //console.log("apiString = ", apiString);

      //console.log(
        //"GetSpecialityDataByYear",
        //apiPayload.year,
        //apiPayload.speciality
      //);

      return axios
        .get(apiString)
        .then((specialityData) => {
          commit("SAVE_SPECIALITY_DATA", specialityData);
          //console.log("specialityData",specialityData);
          return specialityData;
        })
        .catch((res) => {
          //console.log ("catch data");
          //console.log(res.response.data)
          throw res.response.data;
        });
    },
    getRemoteAddress ({commit}, options)
    {
      console.log ("axios: getRemoteAddress");

      axios
        .get("version3")
        .then((thisdata) => {
          commit("SAVE_LOCATIONS_STATE", thisdata);

         console.log (thisdata);

          console.log ("axios: getRemoteAddress => done");
  
          return thisdata;
        })
        .catch((res) => {
          throw res.response.data;
        });       
    },
    GetData({ commit }, apiPayload) {
      //console.log("GetData: ");
      //console.log ("-- apiString   : ", apiPayload.apiString);
      //console.log ("-- speciality  : ", apiPayload.speciality);
      //console.log ("-- reference   : ", apiPayload.reference);

      return axios
        .get(apiPayload.apiString)
        .then((specialityData) => {
          commit("SAVE_SPECIALITY_DATA", specialityData);
          console.log("GetData", specialityData);
          return specialityData;
        })
        .catch((res) => {
          //console.log ("catch data");
          //console.log(res.response.data)
          throw res.response.data;
        });
    },
    fetchSpecialityData({ commit }, speciality) {
      console.log("-------");
      console.log("actions: prototypedata.js: fetchSpecialityData");
      console.log("speciality.speciality: ", speciality.speciality);
      console.log("speciality.apiString: ", speciality.apiString);
      console.log("-------");
      return axios
        .get(speciality.apiString)
        .then((specialityData) => {
          commit("SAVE_SPECIALITY_NAME", speciality.speciality);
          commit("SAVE_SPECIALITY_DATA", speciality);
          console.log("specialityData", specialityData);
          return specialityData;
        })
        .catch((res) => {
          //console.log ("catch data");
          //console.log(res.response.data)
          throw res.response.data;
        });
    },
    attemptFetchStageTwo({ commit }, speciality2) {
      console.log ("attemptFetchStageTwo: ", speciality2.apiString);
      return axios
        .get(speciality2.apiString)
        .then((stageTwo) => {
          commit("SAVE_SPECIALITY_NAME", speciality2.speciality);
          commit("SAVE_SPECIALITY_STAGE_TWO", stageTwo);
          //console.log(stageTwo)
          return stageTwo;
        })
        .catch((res) => {
          //console.log(res.response.data)
          throw res.response.data;
        });
    },
    attemptFetchStageThree({ commit }, speciality) {
      //console.log ("attemptFetchStageThree: ", speciality.apiString);
      return axios
        .get(speciality.apiString)
        .then((stageThree) => {
          commit("SAVE_SPECIALITY_NAME", speciality.speciality);
          commit("SAVE_SPECIALITY_STAGE_THREE", stageThree);
          return stageThree;
        })
        .catch((res) => {
          //console.log(res.response.data)
          throw res.response.data;
        });
    },
    attemptFetchStageSeven({ commit }, speciality) {
      //console.log ("attemptFetchStageSeven: ", speciality.apiString);
      return axios
        .get(speciality.apiString)
        .then((stageThree) => {
          commit("SAVE_SPECIALITY_NAME", speciality.speciality);
          commit("SAVE_SPECIALITY_STAGE_THREE", stageThree);
          return stageThree;
        })
        .catch((res) => {
          //console.log(res.response.data)
          throw res.response.data;
        });
    },
    attemptFetchStageTen({ commit }, speciality) {
      console.log ("attemptFetchStageTen: ", speciality.apiString);
      return axios
        .get(speciality.apiString)
        .then((stageThree) => {
          commit("SAVE_SPECIALITY_NAME", speciality.speciality);
          commit("SAVE_SPECIALITY_STAGE_THREE", stageThree);
          return stageThree;
        })
        .catch((res) => {
          //console.log(res.response.data)
          throw res.response.data;
        });
    },
    getBDFStageThreeSummaries({ commit }, options) {
      //console.log("getBDFStageThreeSummaries: ", options);

      var newApiCall = options + "Year1";
      console.log ("getBDFStageThreeSummaries: newAPiCall: ", newApiCall);

      axios
        .get(newApiCall)
        .then((thisdata) => {
          commit("SAVE_SPECIALITY_STAGE_THREE_YEAR_ONE", thisdata);
  
          return thisdata;
        })
        .catch((res) => {
          throw res.response.data;
        }); 

      newApiCall = options + "Year2"; 
      //console.log ("newAPiCall: ", newApiCall);

      axios
        .get(newApiCall)
        .then((thisdata) => {          
          commit("SAVE_SPECIALITY_STAGE_THREE_YEAR_TWO", thisdata);
          return true;
        })
        .catch((res) => {
          throw res.response.data;
        });


      newApiCall = options + "Year3";

      //console.log ("newApiCall: ", newApiCall);

      axios
        .get(newApiCall) 
        .then((thisdata) => {
          commit("SAVE_SPECIALITY_STAGE_THREE_YEAR_THREE", thisdata);
          return thisdata;
        })
        .catch((res) => {
          throw res.response.data;
        });

        newApiCall = options + "Year4";

      axios
        .get(newApiCall) 
        .then((thisdata) => {
          commit("SAVE_SPECIALITY_STAGE_THREE_YEAR_FOUR", thisdata);
          return thisdata;
        })
        .catch((res) => {
          throw res.response.data;
        });

      newApiCall = options + "Year5";

      //console.log ("newApiCall: ", newApiCall);

      axios
        .get(newApiCall) 
        .then((thisdata) => {
          commit("SAVE_SPECIALITY_STAGE_THREE_YEAR_FIVE", thisdata);          
          return thisdata;
        })
        .catch((res) => {
          throw res.response.data;
        });

      //newApiCall = options + "Year5";

      //console.log ("newApiCall: ", newApiCall);
      //console.log ("call finished.")
    },
    getBDFStageSevenSummaries({ commit }, options) {

      var newApiCall = options + "Year1";

      //console.log ("newApiCall: ", newApiCall);

      axios
        .get(newApiCall)
        .then((thisdata) => {
          commit("SAVE_SPECIALITY_STAGE_SEVEN_YEAR_ONE", thisdata);
  
          return thisdata;
        })
        .catch((res) => {
          throw res.response.data;
        }); 

               
      newApiCall = options + "Year2"; 

      axios
        .get(newApiCall)
        .then((thisdata) => {      
          commit("SAVE_SPECIALITY_STAGE_SEVEN_YEAR_TWO", thisdata);
          return true;
        })
        .catch((res) => {
          throw res.response.data;
        });

      newApiCall = options + "Year3";

      axios
        .get(newApiCall) 
        .then((thisdata) => {
          commit("SAVE_SPECIALITY_STAGE_SEVEN_YEAR_THREE", thisdata);
          return thisdata;
        })
        .catch((res) => {
          throw res.response.data;
        });

        newApiCall = options + "Year4";

      axios
        .get(newApiCall) 
        .then((thisdata) => {
          commit("SAVE_SPECIALITY_STAGE_SEVEN_YEAR_FOUR", thisdata);
          return thisdata;
        })
        .catch((res) => {
          throw res.response.data;
        });

      newApiCall = options + "Year5";

      axios
        .get(newApiCall) 
        .then((thisdata) => {
          commit("SAVE_SPECIALITY_STAGE_SEVEN_YEAR_FIVE", thisdata);          
          return thisdata;
        })
        .catch((res) => {
          throw res.response.data;
        });
        //console.log ("call finished.")
    },
    getBDFStageTenSummaries({ commit }, options) {

      var newApiCall = options + "Year1";

      //console.log ("newApiCall: ", newApiCall);

      axios
        .get(newApiCall)
        .then((thisdata) => {
          commit("SAVE_SPECIALITY_STAGE_TEN_YEAR_ONE", thisdata);
  
          return thisdata;
        })
        .catch((res) => {
          throw res.response.data;
        }); 

               
      newApiCall = options + "Year2"; 

      axios
        .get(newApiCall)
        .then((thisdata) => {      
          commit("SAVE_SPECIALITY_STAGE_TEN_YEAR_TWO", thisdata);
          return true;
        })
        .catch((res) => {
          throw res.response.data;
        });

      newApiCall = options + "Year3";

      axios
        .get(newApiCall) 
        .then((thisdata) => {
          commit("SAVE_SPECIALITY_STAGE_TEN_YEAR_THREE", thisdata);
          return thisdata;
        })
        .catch((res) => {
          throw res.response.data;
        });

        newApiCall = options + "Year4";

      axios
        .get(newApiCall) 
        .then((thisdata) => {
          commit("SAVE_SPECIALITY_STAGE_TEN_YEAR_FOUR", thisdata);
          return thisdata;
        })
        .catch((res) => {
          throw res.response.data;
        });

      newApiCall = options + "Year5";

      axios
        .get(newApiCall) 
        .then((thisdata) => {
          commit("SAVE_SPECIALITY_STAGE_TEN_YEAR_FIVE", thisdata);          
          return thisdata;
        })
        .catch((res) => {
          throw res.response.data;
        });
        //console.log ("call finished.")
    },
    getBDFStageFourSummariesNew({ commit }, options) {
      console.log("getBDFStageFourSummariesNew: ", options);

      var newApiCall = options + "year1";
      //console.log ("newAPiCall: ", newApiCall);

      axios
        .get(newApiCall)
        .then((thisdata) => {
          commit("SAVE_SPECIALITY_STAGE_SEVEN_YEAR_ONE", thisdata);
  
          return thisdata;
        })
        .catch((res) => {
          throw res.response.data;
        }); 

      
      newApiCall = options + "year2"; 
      //console.log ("newAPiCall: ", newApiCall);

      axios
        .get(newApiCall)
        .then((thisdata) => {    
          commit("SAVE_SPECIALITY_STAGE_SEVEN_YEAR_TWO", thisdata);
         // console.log("stageThreeYearOne", stageThreeYearOne);
          return true;
        })
        .catch((res) => {
          throw res.response.data;
        });


      newApiCall = options + "year3";

      //console.log ("newApiCall: ", newApiCall);

      axios
        .get(newApiCall) // "grandsummarythreeyeartwo-collection"
        .then((thisdata) => {
          commit("SAVE_SPECIALITY_STAGE_SEVEN_YEAR_THREE", thisdata);
          //console.log("stageThreeYearTwo", stageThreeYearTwo);
          return thisdata;
        })
        .catch((res) => {
          throw res.response.data;
        });

        newApiCall = options + "year4";

        //console.log ("newApiCall: ", newApiCall);

      axios
        .get(newApiCall) //"grandsummarythreeyearthree-collection"
        .then((thisdata) => {
          commit("SAVE_SPECIALITY_STAGE_SEVEN_YEAR_FOUR", thisdata);
          //console.log("stageThreeYearThree", stageThreeYearThree);
          return thisdata;
        })
        .catch((res) => {
          throw res.response.data;
        });

      newApiCall = options + "year5";

      //console.log ("newApiCall: ", newApiCall);

      axios
        .get(newApiCall) //"grandsummarythreeyearfour-collection"
        .then((thisdata) => {
          commit("SAVE_SPECIALITY_STAGE_SEVEN_YEAR_FIVE", thisdata);
          //console.log("stageThreeYearFour", stageThreeYearFour);
          return thisdata;
        })
        .catch((res) => {
          throw res.response.data;
        });
    },

    getBDFStageFourSummaries({ commit }, options) {
      //console.log("getBDFStageThreeSummaries: ", options);

      var newApiCall = options + "year1";
      //console.log ("newAPiCall: ", newApiCall);

      axios
        .get(newApiCall)
        .then((thisdata) => {
          commit("SAVE_SPECIALITY_STAGE_FOUR_YEAR_ONE", thisdata);
  
          return thisdata;
        })
        .catch((res) => {
          throw res.response.data;
        }); 

      
      newApiCall = options + "year2"; 
      //console.log ("newAPiCall: ", newApiCall);

      axios
        .get(newApiCall)
        .then((thisdata) => {
          commit("SAVE_SPECIALITY_STAGE_FOUR_YEAR_TWO", thisdata);
         // console.log("stageThreeYearOne", stageThreeYearOne);
          return true;
        })
        .catch((res) => {
          throw res.response.data;
        });


      newApiCall = options + "year3";

      //console.log ("newApiCall: ", newApiCall);

      axios
        .get(newApiCall) // "grandsummarythreeyeartwo-collection"
        .then((thisdata) => {
          commit("SAVE_SPECIALITY_STAGE_FOUR_YEAR_THREE", thisdata);
          //console.log("stageThreeYearTwo", stageThreeYearTwo);
          return thisdata;
        })
        .catch((res) => {
          throw res.response.data;
        });

        newApiCall = options + "year4";

        //console.log ("newApiCall: ", newApiCall);

      axios
        .get(newApiCall) //"grandsummarythreeyearthree-collection"
        .then((thisdata) => {
          commit("SAVE_SPECIALITY_STAGE_FOUR_YEAR_FOUR", thisdata);
          //console.log("stageThreeYearThree", stageThreeYearThree);
          return thisdata;
        })
        .catch((res) => {
          throw res.response.data;
        });

      newApiCall = options + "year5";

      //console.log ("newApiCall: ", newApiCall);

      axios
        .get(newApiCall) //"grandsummarythreeyearfour-collection"
        .then((thisdata) => {
          commit("SAVE_SPECIALITY_STAGE_FOUR_YEAR_FIVE", thisdata);
          //console.log("stageThreeYearFour", stageThreeYearFour);
          return thisdata;
        })
        .catch((res) => {
          throw res.response.data;
        });

      newApiCall = options + "2028";

      /*console.log ("newApiCall: ", newApiCall);

      axios
        .get(newApiCall) //"grandsummarythreeyearfive-collection"
        .then((thisdata) => {
          commit("SAVE_SPECIALITY_STAGE_FOUR_YEAR_SIX", thisdata);
          //console.log("stageThreeYearFive", stageThreeYearFive);
          return thisdata;
        })
        .catch((res) => {
          throw res.response.data;
        });*/

        //console.log ("call finished.")
    },
    getBDFStageNineSummaries({ commit }) {
      console.log ("axios: getBDFStageNineSummaries");

      axios
        .get("new_bdf_stage_9")
        .then((thisdata) => {
          commit("SAVE_SPECIALITY_STAGE_NINE", thisdata);

          console.log ("axios: getBDFStageNineSummaries => done");
  
          return thisdata;
        })
        .catch((res) => {
          throw res.response.data;
        });       
    },
    getBDFStageSixSummaries({ commit }) {
      console.log ("axios: getBDFStageSixSummaries");

      axios
        .get("new_bdf_stage_6")
        .then((thisdata) => {
          commit("SAVE_SPECIALITY_STAGE_SIX", thisdata);

          console.log ("axios: getBDFStageSixSummaries => done");
  
          return thisdata;
        })
        .catch((res) => {
          throw res.response.data;
        });       
    },
    getBDFStageFiveSummaries({ commit }) {
      console.log ("axios: getBDFStageFiveSummaries");

      axios
        .get("england_stage_5")
        .then((thisdata) => {
          commit("SAVE_SPECIALITY_STAGE_FIVE", thisdata);

          console.log ("axios: getBDFStageFiveSummaries => done");
  
          return thisdata;
        })
        .catch((res) => {
          throw res.response.data;
        });       
    },
    getBDFStageFiveInsights({ commit }) {
      console.log ("axios: getBDFStageFiveInsights");

      axios
        .get("england_stage_5_actionable_insights")
        .then((thisdata) => {
          commit("SAVE_SPECIALITY_STAGE_FIVE_INSIGHTS", thisdata);

          console.log ("axios: getBDFStageFiveInsights => done");
  
          return thisdata;
        })
        .catch((res) => {
          throw res.response.data;
        });       
    },
    getBDFStageEightSummaries({ commit }) {
      console.log ("axios: getBDFStageEightSummaries");

      axios
        .get("new_bdf_stage_8")
        .then((thisdata) => {
          commit("SAVE_SPECIALITY_STAGE_EIGHT", thisdata);

          console.log ("axios: getBDFStageEightSummaries => done");
  
          return thisdata;
        })
        .catch((res) => {
          throw res.response.data;
        });       
    },
    getBDFStageEightInsights({ commit }) {
      console.log ("axios: getBDFStageEightInsights");

      axios
        .get("new_bdf_stage_8_actionable_insights")
        .then((thisdata) => {
          commit("SAVE_SPECIALITY_STAGE_EIGHT_INSIGHTS", thisdata);

          console.log ("axios: getBDFStageEightInsights => done");
  
          return thisdata;
        })
        .catch((res) => {
          throw res.response.data;
        });       
    },
    getStageThreeSummaries({ commit }) {
      console.log("getStageThreeSummaries");

      axios
        .get("grandsummarythreeyearone-collection")
        .then((stageThreeYearOne) => {
          commit("SAVE_SPECIALITY_STAGE_THREE_YEAR_ONE", stageThreeYearOne);
          console.log("stageThreeYearOne", stageThreeYearOne);
          return stageThreeYearOne;
        })
        .catch((res) => {
          throw res.response.data;
        });

      axios
        .get("grandsummarythreeyeartwo-collection")
        .then((stageThreeYearTwo) => {
          commit("SAVE_SPECIALITY_STAGE_THREE_YEAR_TWO", stageThreeYearTwo);
          console.log("stageThreeYearTwo", stageThreeYearTwo);
          return stageThreeYearTwo;
        })
        .catch((res) => {
          throw res.response.data;
        });

      axios
        .get("grandsummarythreeyearthree-collection")
        .then((stageThreeYearThree) => {
          commit("SAVE_SPECIALITY_STAGE_THREE_YEAR_THREE", stageThreeYearThree);
          console.log("stageThreeYearThree", stageThreeYearThree);
          return stageThreeYearThree;
        })
        .catch((res) => {
          throw res.response.data;
        });

      axios
        .get("grandsummarythreeyearfour-collection")
        .then((stageThreeYearFour) => {
          commit("SAVE_SPECIALITY_STAGE_THREE_YEAR_FOUR", stageThreeYearFour);
          console.log("stageThreeYearFour", stageThreeYearFour);
          return stageThreeYearFour;
        })
        .catch((res) => {
          throw res.response.data;
        });

      axios
        .get("grandsummarythreeyearfive-collection")
        .then((stageThreeYearFive) => {
          commit("SAVE_SPECIALITY_STAGE_THREE_YEAR_FIVE", stageThreeYearFive);
          console.log("stageThreeYearFive", stageThreeYearFive);
          return stageThreeYearFive;
        })
        .catch((res) => {
          throw res.response.data;
        });
    },
    attemptFetchAnnualTotalsOne({ commit }) {
      console.log ("attemptFetchAnnualTotalsOne");
      return axios
        .get("annualtotals-collection")
        .then((annualTotalsDataOne) => {
          commit("SAVE_ANNUALTOTALS_DATA_ONE", annualTotalsDataOne);
          //console.log(annualTotalsDataOne)
          return annualTotalsDataOne;
        })
        .catch((res) => {
          //console.log(res.response.data)
          throw res.response.data;
        });
    },
    attemptFetchAnnualTotalsTwo({ commit }) {
      console.log("attemptFetchAnnualTotalsTwo");
      return axios
        .get("annualtotals-collection")
        .then((annualTotalsDataTwo) => {
          commit("SAVE_ANNUALTOTALS_DATA_TWO", annualTotalsDataTwo);
          //console.log(annualTotalsDataTwo)
          return annualTotalsDataTwo;
        })
        .catch((res) => {
          //console.log(res.response.data)
          throw res.response.data;
        });
    },
    attemptPostBlog(context, data) {
      console.log ("attemptPostBlog");
      return new Promise((resolve, reject) => {
        axios
          .post("/blogs", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error.response.data.errors);
          });
      });
    },

    registerUser(context, data) {

      console.log ("registerUser");
      return new Promise((resolve, reject) => {
        axios
          .post("/register", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error.response.data.errors);
          });
      });
    },
    postBlog(context, data) {
      console.log ("postBlog");
      return new Promise((resolve, reject) => {
        axios
          .post("/blog", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error.response.data.errors);
          });
      });
    },
    saveSettings(context, data) {
      console.log ("savesettings");
      return new Promise((resolve, reject) => {
        axios
          .post("/savesettings", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error.response.data.errors);
          });
      });
    },
    getSettings({ commit }) {
      console.log("getsettings");
      return axios
        .get("/getsettings")
        .then((thisdata) => {
          commit("SAVE_SETTINGS_DATA", thisdata);
          return thisdata;
        })
        .catch((res) => {        
          throw res.response.data;
        });
    },
    
    postBetterSettings(context, data) {
      console.log ("postBetterSettings");
      return new Promise((resolve, reject) => {
        axios
          .post("/bettersettings", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error.response.data.errors);
          });
      });
    },
    postBlogTest(context, data) {
      console.log ("postBlogTest");
      return new Promise((resolve, reject) => {
        axios
          .post("/auth-blog-passthrough", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error.response.data.errors);
          });
      });
    },
    attemptPostUserData(context, data) 
    {
      console.log ("attemptPostUserData (", data, ")");
      return new Promise((resolve, reject) => {
        axios
          .post("/UpdateTestData", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error.response.data.errors);
          });
      });
    },
  },
};
